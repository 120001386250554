<template>
  <div class="setting-box">
    <van-nav-bar title="账号设置" left-text="返回" left-arrow @click-left="onClickLeft" />
    <div class="list-box" v-if="userInfo">
      <p>账号信息</p>
      <div class="list">
        <span>手机号：{{ userInfo.userSelfhelpVO.mobile }}</span>
        <i @click="editTel">修改</i>
      </div>
      <div class="list">
        <span>密 码：**不可见**</span>
        <i @click="editPwd">修改</i>
      </div>
    </div>
    <div class="list-box" v-if="userInfo">
      <p>
        <span>基本信息</span>
        <i @click="editInfo">编辑</i>
      </p>
      <div class="list">
        <span>姓名：{{ userInfo.userSelfhelpVO.userName }}</span>
      </div>
      <div class="list">
        <span>
          姓别：{{
          userInfo.userSelfhelpVO.gender === 'MALE' ? '男' : '女'
          }}
        </span>
      </div>
      <div class="list">
        <span>当前职位：{{userInfo.userSelfhelpVO.position}}</span>
      </div>
      <div class="list">
        <span>微信号：{{ userInfo.userSelfhelpVO.wechat }}</span>
      </div>
      <!-- <div class="list">
        <span>绑定公司：{{ userInfo.companyVO.nameCn }}</span>
      </div>-->
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  data() {
    return {
      show: false,
      userInfo: null,
    };
  },
  mounted() {
    let userInfo = localStorage.getItem('userInfo');
    if (userInfo) {
      this.userInfo = JSON.parse(userInfo);
    }
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    editTel() {
      this.$router.push('/myHome/editTel');
    },
    editPwd() {
      this.$router.push('/myHome/editPwd');
    },
    editInfo() {
      this.$router.push({ path: '/myHome/baseInfoEdit' }); // , query: { type: 'edit' }
    },
  },
};
</script>
<style scoped lang="scss">
.setting-box {
  height: 100%;
  width: 100%;
  background: #ffffff;
  .list-box {
    padding: 0 16px;
    margin: 20px 0 40px 0;
    p {
      font-size: 18px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
      i {
        font-size: 15px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #206cfe;
      }
    }
    i {
      font-style: normal;
    }
  }
  .list {
    height: 40px;
    line-height: 40px;
    display: flex;
    justify-content: space-between;
    span {
      font-size: 15px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
    }
    i {
      font-size: 15px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #206cfe;
      font-style: normal;
    }
  }
}
</style>
